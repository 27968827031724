@import "../../../../styles/mixins";

.profileImage {
  display: flex;
  align-items: center;
  width: 100%;
  .imgWrapper {
    border-radius: 50%;
    border-color: white;
    border-width: 0.3rem;
    overflow: hidden;
    position: relative;
    .img {
      background-position-x: 50%;
      background-position-y: 0%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .inHeader {
    width: 347px;
    height: 347px;
  }

  .inNavbar {
    width: 42px;
    height: 42px;
  }

  .overlay {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    color: #fff;
  }
}

@include tablet {
  .profileImage {
    .inHeader {
      width: 89px;
      height: 89px;
    }
  }
}
