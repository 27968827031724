@import "../../../../styles/mixins";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 100;

  @media only screen and (max-width: $mobile) {
    top: $topbarHeight;
  }
}
.modal {
  min-height: 500px;
  border-radius: 36px;
  z-index: 101;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: #ffffff;
  min-width: 480px;

  @media only screen and (max-width: 1200px) and (min-width: $mobile) {
    width: calc(100% - 10rem);
  }

  @media only screen and (max-width: $mobile) {
    width: 100%;
    height: calc(100% - #{$topbarHeight});
    border-radius: 0;
    transform: none;
    top: 0;
    left: 0;
    min-width: unset;
  }
  .contentWrapper {
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: 100%;
    max-width: 100%;

    .content {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: 100%;
      overflow: auto;
      padding-left: 3.5rem;
      padding-right: 3.5rem;

      & > * {
        height: 100%;
      }
    }
  }
  .close {
    position: absolute;
    top: 18px;
    right: 25px;
    cursor: pointer;
    padding: 0.4rem;
  }
}
