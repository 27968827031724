@import "./mixins";

* {
  font-family: "Fira Sans", sans-serif !important;
  letter-spacing: 1.35px;
}

#root {
  height: 100%;
}

.containerContent {
  min-height: calc(100% - 53px);
}


a {
  color: #000;

  &.link {
    font-weight: bold;
  }

  &.link:hover {
    padding-bottom: 2px;
    border-bottom: 3px solid black;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #232323;
}

.h1 {
  font-size: 42pt;
  line-height: 48pt;
  font-weight: 800;
  margin-bottom: 1rem;
}

.h2 {
  font-weight: 700;
  line-height: 32pt;
  font-size: 32pt;
  margin-bottom: 1rem;
}

.h3 {
  font-size: 25px;
}

.btnNaked {
  background: none;
  border: none;
  outline: none;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
}

.cta-btn {
  margin: 10px 0px;
  background: #52c41a !important;
  border-color: #65c436 !important;
  color: #fff !important;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.powerdoc-btn-doc-card {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  padding: 0.4rem 1.6rem;
  border: 1px solid #000000;
  border-radius: 500px;
  font-size: 14px;

  &:hover {
    border: 1px solid #000000;
    color: white !important;
    background-color: black;
  }
}

.powerdoc-btn-basic {
  background-color: #fff !important;
  color: #d33f49 !important;
  border: 1px solid #000000 !important;
  border-radius: 500px !important;
  font-size: 14px;

  &:hover {
    border: 1px solid #000000;
    color: white;
    background-color: black;
  }
}

.powerdoc-btn-primary {
  background-color: #d33f49 !important;
  color: #fff !important;
  border: none !important;
  border-radius: 500px !important;
  font-size: 14px;

  &:hover {
    color: white;
    background-color: black;
  }
}

.powerdoc-btn-secondary {
  background-color: #fff !important;
  color: #d33f49 !important;
  border: none !important;
  border-radius: 500px !important;
  font-size: 14px;

  &:hover {
    color: white;
    background-color: black;
  }
}
