@import "../../../../styles/mixins";

.topbar {
  -webkit-transition: opacity 250ms;
  /* Safari prior 6.1 */
  transition: opacity 250ms;
  display: grid;
  grid-template-columns: minmax(140px, 250px) 1fr minmax(170px, 300px) 50px minmax(90px,
      150px);
  grid-template-areas: "logo search upload profileImg menuBtn";
  color: #fff;
  height: 55px;

  /*Menu Items*/

  .item {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .topbarLogo {
    grid-area: logo;

    a {
      background-image: url("../../graphics/topbar/logo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
      width: 130px;
      height: 28px;
    }
  }

  .upload {
    grid-area: upload;
  }

  .search {
    grid-area: search;
  }

  .menuBtn {
    grid-area: menuBtn;

    a {
      color: #fff;
    }
  }

  .profileImg {
    grid-area: profileImg;
  }

  .white {
    color: white;
  }

  .mobileMenu {
    position: static;
    width: 100%;
    height: 100%;
    background-color: orange;
  }
}

@include tablet {
  .topbar {

    grid-template-columns: 50px 50px 1fr 50px 50px;
    grid-template-areas: "upload search logo profileImg menuBtn";

    /*    
    &.ant-menu-horizontal {
      .ant-menu-item {
        height: 55px;
      }
    }
    */
  }

  .topbar-logo {
    margin: 0 auto;
    min-width: 130px;
  }
}