.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
    margin-right: 1rem;

    & > * {
        width: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
            text-align: center;
        }
        margin-top: 2rem;
    }
}
