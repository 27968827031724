@import "../../../../styles/mixins";

.footer {
  padding-right: 60px;
  padding-left: 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-columns: repeat(6, 1fr);
  background-color: $gray;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #fff;

  a {
    text-align: center;
    color: #fff;
    font-weight: bold;
  }
}