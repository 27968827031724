.container {
  & > * {
    display: block !important;
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
}
.centered {
  flex-direction: column;
}
