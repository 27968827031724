@import "../../../../styles/mixins";

.menu {
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  height: calc(100% - 55px);
  background-color: $websiteBackground;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;

  .item {
    text-align: center;
    border-bottom: 2px solid black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: bold;
    text-transform: uppercase;

    &:first-of-type {
      border-top: 2px solid black;
      margin-top: 4rem;
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 1rem;
  }
}
