@import "../../../../styles/mixins";

.button {
  background: lightgray;
  border: none;
  border-radius: 10px;
  color: black;

  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin: 0.3rem;

  font-weight: bold;
  cursor: pointer;
  opacity: 0.9;

  &.customIcon {
    padding: 0;
    svg {
      height: 100%;
    }
    // svg *{stroke:red}
  }
}
.button:hover,
.button:focus,
.button:active {
  background: lightgray;
  opacity: 1;
}
.button:focus {
  outline: none;
}

// Large: Used on the porfile

.large {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 500px;
  font-size: 18px;
  padding-left: 52px;
  padding-right: 52px;
  letter-spacing: 1.35px;
  height: 42px;

  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: black;
  }
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.largeInverted {
  background-color: black;
  color: white;
  &:hover,
  &:active,
  &:focus {
    background-color: white;
    color: black;
  }
  &:active,
  &:focus {
    background-color: rgba(255, 255, 255, 0.7);
  }
}

.border {
  border: 1px solid #000000 !important;
  &:hover {
    border: 1px solid #000000 !important;
  }
}

.basic {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 500px;
  font-size: 14px;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: black;
  }
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.primary {
  background-color: $primary;
  color: $primaryText;
  border: none;
  border-radius: 500px;
  font-size: 14px;
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: black;
  }
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.secondary {
  background-color: $secondary;
  color: $secondaryText;
  border: none;
  border-radius: 500px;
  font-size: 14px;
  &.customIcon > svg {
    width: 32px;
  }
  &:hover,
  &:active,
  &:focus {
    color: white;
    background-color: black;
    &.customIcon svg * {
      stroke: white;
    }
  }
  &:active,
  &:focus {
    background-color: rgba(0, 0, 0, 0.7);
  }
}
