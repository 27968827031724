$primary: #d33f49;
$primaryLight: rgba(211, 63, 73, 0.05);
$primaryActive: #d33f49;
$primaryText: #fff;

$secondary: #fff;
$secondaryActive: #fff;
$secondaryText: #d33f49;

$gray: #a0a0a0;
$gray2: #707070;
$websiteBackground: #fafafa;
$blue2: #679ac6;

$large: 1140px;
$desktop: 1024px;
$tablet: 768px;
$mobile: 480px;

$topbarHeight: 55px;

@mixin large {
  @media only screen and (max-width: $large) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (max-width: $desktop) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media only screen and (max-width: $mobile) {
    @content;
  }
}
